@import '~ethos-design-system/src/components/Media/Media.scss';

.Question {
  padding-bottom: var(--Space-8);

  svg {
    fill: var(--GrayPrimary--translucent);
    margin-left: var(--Space-4);
  }
}

.Single {
  padding-bottom: var(--Space-32);

  &:hover span {
    text-decoration: underline;
  }
}
