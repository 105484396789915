@import '~ethos-design-system/src/components/Media/Media.scss';
@import './Mixins';

@include for-phone-and-small-tablet {
  .boxed {
    .moss {
      background-color: var(--BrandMoss);
    }

    .duckegg {
      background-color: var(--BrandDuckegg);
    }

    .white {
      background-color: var(--White);
    }

    .buttercup {
      background-color: var(--BrandButtercup);
    }

    .gray {
      background-color: var(--GrayLightHover--opaque);
    }
  }
}

.container {
  max-width: var(--ContainerMaxWidth);
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &.hero {
    /** desktop spec is [560px | 880px] column split (total 1440)
        this is where the 39/61% split comes from below */
    @include for-large-laptop-and-up {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    .image {
      margin-bottom: var(--Space-48);
      max-height: 216px;
      overflow-y: hidden;

      img {
        max-width: 100%;
        @include for-small-laptop-only {
          transform: translateY(-30%);
        }
      }

      @include for-large-laptop-and-up {
        margin-bottom: 0;
        max-width: calc(39% - var(--Space-48));
        max-height: none;
      }

      @include for-desktop-only {
        max-width: 39%;
      }
    }

    .inner {
      @include for-large-laptop-and-up {
        max-width: calc(61%);
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
      }

      @include for-desktop-only {
        max-width: calc(61% - 120px);
      }
    }
  }
}

.widget {
  &.boxed {
    @include for-laptop-only {
      margin-top: var(--Space-56);
    }

    @include for-desktop-only {
      margin-top: var(--Space-80);
    }
  }
}

.hideMediaMobile {
  @include for-phone-only {
    display: none;
  }
}

.disclaimer {
  color: var(--GraySecondary--opaque);
  margin-bottom: var(--Space-32);
  padding-top: var(--Space-16);

  @include for-phone-and-small-tablet {
    padding-top: 0;
    padding-bottom: var(--Space-16);
  }

  @include for-tablet-and-up {
    text-align: center;
  }

  @include for-desktop-only {
    margin-bottom: var(--Space-80);
  }
}

.hero .disclaimer {
  display: none;
  @include for-phone-and-small-tablet {
    display: block;
  }
}

.body {
  .floatingContainer {
    .StyledReactSelect__control {
      border-radius: 2px !important;
      border-width: 0 !important;
    }
    &:not([class*='Fullbleed-module--fullBleed']):not(
        [class*='Floating-module--singleCTA']
      ):not([class*='ForegroundImage']):not(
        [class*='SingleCTA-module--singleCTA']
      ) {
      @media (min-width: 1920px) {
        .overflow-hidden picture {
          position: relative;
          bottom: 125px;
        }
      }
    }
  }
}

.email {
  background: var(--BrandDuckegg);
  padding: var(--Space-64) var(--Space-24);
  max-width: 340px;
  display: flex;
  flex-direction: column;
  position: relative;

  .close {
    width: var(--Space-32);
    height: var(--Space-32);
    top: var(--Space-12);
    right: var(--Space-12);
    padding: 0;

    background: transparent;
    border: unset;
    position: absolute;
    cursor: pointer;
    outline: none;
  }

  .emailHeading {
    margin-bottom: var(--Space-8);
  }

  button[type='submit'] {
    margin-top: var(--Space-80);
  }

  &.floating {
    background: var(--White);
    border-radius: 8px;

    .close {
      width: var(--Space-32);
      height: var(--Space-32);
      top: var(--Space-12);
      right: var(--Space-12);
      padding: 0;

      background: transparent;
      border: unset;
      position: absolute;
      cursor: pointer;
      outline: none;
    }

    svg {
      max-width: 100%;
      height: auto;
    }

    input[type='email'] {
      border-radius: 2px;
    }
  }
}

.wrapper {
  position: relative;
}
.loader {
  border-radius: 0;
  background: linear-gradient(27deg, #daefe3 8.28%, #c0d8d5 91.72%);
  min-height: unset;
  @include for-tablet-and-up {
    min-height: 600px;
  }
}
