@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-tablet-and-laptop-mid {
  @media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
    @content;
  }
}

.container {
  background: white;
  padding: 32px 24px;

  @media (min-width: 1024px) {
    position: relative;
    background: transparent;
    padding: 0;
  }

  @include for-tablet-and-laptop-mid {
    padding: 40px 72px 40px;
  }
}

.floatingWidget {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: -140px;
    z-index: 9999;
    left: 0;
    bottom: 0;
    width: 80%;
    height: max-content;
    padding: 64px 80px;
    background-color: white;
    align-items: start;
    max-width: 1130px;
    align-items: center;

    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border-radius: 24px;
    padding: 40px;
  }

  @media (min-width: 1200px) {
    padding: 64px 80px;
  }

  .buttonContainer {
    width: 100%;
    margin-bottom: 64px;

    @media (min-width: 1024px) {
      width: 360px;
      margin-bottom: 0;
    }
  }

  .heading {
    color: #272727;
    font-family: NewTheinhardt;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 32px;

    @media (min-width: 1024px) {
      font-size: 28px;
      font-weight: 500;
      letter-spacing: -0.28px;
      line-height: 19px;
    }
  }

  .list {
    margin: 0;
    padding-left: 32px;

    :not(:last-child) {
      margin-bottom: 8px;
    }

    li {
      color: #272727;
      font-family: NewTheinhardt;
      font-size: 18px;
      font-weight: 400;
      line-height: 18px;

      strong {
        font-weight: 500;
      }
    }
    @media (min-width: 1024px) {
      padding-left: 40px;
      li {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.24px;
        line-height: 26px;
      }

      :not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }

  .cta {
    font-size: 24px;
  }

  .phoneNumber {
    letter-spacing: 0em;
    color: #05594f;
    font-size: 28px;
    text-decoration: underline;
    text-underline-offset: 6px;
    font-weight: 500;
    text-decoration-thickness: 2px;
    margin: 0;
    line-height: 19px;
  }

  .phoneText {
    color: #000;
    text-align: center;
    font-family: NewTheinhardt;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
    line-height: 14px;
  }

  .separatorText {
    font-family: NewTheinhardt;
    text-wrap: nowrap;
    margin: 0 8px;

    color: #101828;

    font-size: 18px;
    font-weight: 500;
    line-height: 12px;
  }
}
