@import '~ethos-design-system/src/components/Media/Media.scss';

.Questions {
  @include for-laptop-and-up {
    display: block;
  }

  .Column {
    flex-grow: 1;

    @include for-laptop-only {
      flex-basis: 240px;

      &:nth-child(odd) {
        margin-right: var(--Space-64);
      }
    }

    @include for-desktop-only {
      flex-basis: 320px;
      max-width: 320px;

      &:nth-child(odd) {
        margin-right: var(--Space-56);
      }
    }
  }

  .PhoneAndTablet {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .LaptopAndUp {
    @media (max-width: 1023px) {
      display: none;
    }

    @media (min-width: 1024px) {
      max-width: 720px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--Space-56);
    }
  }
  .fullWidth {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    max-width: 100%;
  }
}
