@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-tablet-and-laptop-mid {
  @media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
    @content;
  }
}

.wrapper {
  background: linear-gradient(
    135deg,
    #e6f5ec -5.9%,
    rgba(235, 240, 249, 0.98) 138.44%
  );
  display: flex;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
  @include for-laptop-and-up {
    min-height: auto;
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    padding: 32px 120px;
    display: flex;

    @include for-phone-and-laptop-mid {
      padding: 32px 24px;
    }

    @include for-tablet-and-laptop-mid {
      padding: 32px 72px;
    }
  }

  .mainContent {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include for-laptop-and-up {
      align-items: flex-start;
      max-width: 500px;
      width: unset;
    }
  }
}

.trustImages {
  max-width: 510px;
  display: flex;
  align-items: center;
  gap: 42px;
  margin: 48px 0;

  .trustImage {
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
    width: 142px;
  }
}

.header {
  color: #056257;
  font-family: Cambon;
  font-size: 80px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: -2.72px;
  margin-bottom: 32px;
  margin-top: 48px;
  width: 100%;
  text-align: center;
  margin-bottom: 36px !important;

  @include for-laptop-and-up {
    text-align: left;
  }

  @include for-phone-and-laptop-mid {
    font-size: 44px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.88px;
    margin-top: 20px;
    margin-bottom: 24px;
  }
}

.subheader {
  color: #056257;
  text-align: center;
  font-family: Theinhardt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
  margin: 0;

  @include for-tablet-and-up {
    text-align: left;
    font-size: 16px;
  }
}

.logo {
  margin-bottom: 20px;
  img {
    width: 80px;
  }

  @include for-laptop-and-up {
    display: none;
  }
}

.bgContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;

  @include for-laptop-and-up {
    display: block;
  }
}

.bgImage {
  position: absolute;
  right: 200px;
  bottom: 0;
  z-index: 0;

  @include for-tablet-and-laptop-mid {
    right: 100px;

    img {
      width: 460px;
      height: 510px;
    }
  }
}

.button {
  max-width: 500px;
  font-size: 20px !important;
  color: #272727;
}
