@import '~ethos-design-system/src/components/Media/Media.scss';

$accordion-border-color: rgba(0, 0, 0, 0.08);

.root {
  position: relative;
  transition: opacity 0.5s ease;

  .container {
    width: 100%;
    max-width: var(--ContainerMaxWidth);
    margin: 0 auto;
  }

  @include for-laptop-and-up {
    padding-top: var(--Space-32);
    padding-bottom: var(--Space-48);
  }

  &.loaded {
    opacity: 1;
  }

  &:not(.loaded) {
    min-height: 100vh;
    opacity: 0;
  }
}

@include for-phone-only {
  .HeadingMobile {
    padding-bottom: var(--Space-24);
    padding-top: var(--Space-24);
  }

  .TabPanel.Selected {
    .HeadingMobile {
      padding-left: calc(var(--ContainerPadding--Phone) - 4px);
    }
  }
}

@include for-tablet-only {
  .HeadingMobile {
    padding-bottom: var(--Space-40);
    padding-top: var(--Space-40);
  }

  .TabPanel.Selected {
    .HeadingMobile {
      padding-left: calc(var(--ContainerPadding--Tablet) - 4px);
    }
  }
}

@include for-phone-and-tablet {
  .TabPanel.Selected {
    border-bottom: solid 1px $accordion-border-color;

    .AccordionIcon {
      transform: scale(1, -1);
    }

    .Questions {
      display: block;
    }

    .HeadingMobile {
      margin-bottom: var(--Space-32);
      border-left: solid 4px var(--BrandForest);
    }
  }

  .TabPanel:not(:last-child) {
    .HeadingMobile {
      border-bottom: solid 1px $accordion-border-color;
    }
  }

  .HeadingMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .AccordionIcon {
    display: inline;
    padding-left: var(--Space-80);
  }

  .Nav {
    display: none;
  }

  .ViewAll {
    padding-bottom: var(--Space-64);

    span {
      text-decoration: underline;
    }

    svg {
      fill: var(--GrayPrimary--translucent);
      margin-left: var(--Space-4);
    }
  }
}

@include for-laptop-and-up {
  .ViewAll {
    display: none;
  }

  .Nav {
    border-bottom: 1px solid var(--GrayStrokeAndDisabled--translucent);

    .container {
      display: flex;
    }
  }

  .NavItem {
    display: inline-block;
    border-bottom: 2px solid transparent;
    position: relative;
    list-style: none;
    cursor: pointer;
    max-width: 144px;
    vertical-align: top;
    padding-bottom: var(--Space-16);
    word-break: keep-all;

    &:not(:last-child) {
      margin-right: var(--Space-56);
    }

    &.Selected {
      border-color: var(--BrandForest);
      .Label {
        font-weight: 500;
      }
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;

      &:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  .Tabs {
    -webkit-tap-highlight-color: transparent;

    .TabPanel {
      display: none;
      padding-top: var(--Space-72);

      &.Selected {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.Questions {
  display: none;

  @include for-laptop-and-up {
    padding-top: var(--Space-8);
    display: block;
  }
}

.HeadingMobile {
  cursor: pointer;

  @include for-laptop-and-up {
    display: none;
  }
}

.HeadingDesktop {
  @include for-phone-and-tablet {
    display: none;
  }

  @include for-laptop-and-up {
    min-width: 240px;
    width: 240px;
  }

  @include for-laptop-only {
    margin-right: var(--Space-80);
  }
}

.Accordion {
  display: none;

  @include for-phone-and-tablet {
    display: block;
  }
}

.HeadingMobile,
.Questions {
  @include for-phone-only {
    padding-left: var(--ContainerPadding--Phone);
    padding-right: var(--ContainerPadding--Phone);
  }

  @include for-tablet-only {
    padding-left: var(--ContainerPadding--Tablet);
    padding-right: var(--ContainerPadding--Tablet);
  }
}

.Nav .container,
.TabPanel {
  @include for-laptop-only {
    padding-left: var(--ContainerPadding--Laptop);
    padding-right: var(--ContainerPadding--Laptop);
  }

  @include for-desktop-only {
    padding-left: var(--ContainerPadding--Desktop);
    padding-right: var(--ContainerPadding--Desktop);
  }
}

.Single {
  @include for-phone-and-tablet {
    &:nth-child(1n + 4) {
      display: none;
    }
  }
}
