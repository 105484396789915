@import '~ethos-design-system/src/components/Media/Media.scss';
.loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
  pointer-events: none;
  min-height: 100vh;
  @include for-tablet-and-up {
    min-height: auto;
    position: absolute;
  }
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
