@import '~ethos-design-system/src/components/Media/Media.scss';
@import '../../../styles/background-color.module.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-tablet-and-laptop-mid {
  @media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
    @content;
  }
}

.speakWithExpert {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: white;

  .heading {
    font-family: 'Cambon';
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -0.96px;
    justify-content: center;
    color: #000;
    margin-bottom: 16px;
    text-align: center;

    @include for-phone-only {
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -0.32px;
    }
  }

  .mainContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .subHeading {
    color: #272727;
    font-family: NewTheinhardt;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    max-width: 553px;
    text-align: center;
    margin: 0 0 48px;
  }

  .phoneButton {
    display: flex;
    min-height: 56px;
    padding: 32px 48px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #cde0dd;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #04463e;
      text-align: center;

      font-family: NewTheinhardt;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.24px;
      margin-top: -2px;
    }

    &:hover {
      opacity: 0.8;
    }

    @include for-phone-only {
      width: 100%;
    }
  }

  .list {
    margin: 32px 0 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      color: #04463e;
      text-align: center;
      font-family: NewTheinhardt;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      list-style-type: none;
    }
    @include for-phone-only {
      margin-bottom: 16px;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    padding: 100px 120px 120px;

    @include for-phone-and-laptop-mid {
      padding: 80px 24px 0;
    }

    @include for-tablet-and-laptop-mid {
      padding: 80px 72px 0;
    }
  }

  .bgContainer {
    position: absolute;
    max-width: 1440px;
    width: 100%;
    height: 100%;

    @include for-phone-and-laptop-mid {
      position: static;
      height: auto;
      width: auto;
      max-width: initial;
    }
  }

  .bgImage {
    position: absolute;
    bottom: 0px;
    left: 78px;
    z-index: 0;

    img {
      width: 443px;
      height: 435px;
    }

    @include for-laptop-only {
      left: 0;
      img {
        width: 413px;
        height: 405px;
      }
    }

    @include for-phone-and-laptop-mid {
      background: blue;
      position: static;
      width: 100%;
      height: 100%;
      img {
        width: 258px;
        height: 253px;
      }
    }
  }
}
